import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { type FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { configureRoute } from '../../../../utils/routes/configure-route';
import { type ShowroomDetailsQuery, type ShowroomDetailsQueryVariables } from '../../../__generated__/graphql-client-types';
import { AlertNotification } from '../../../components/common-components/alert-notification/alert-notification.component';
import { PageLoading } from '../../../components/common-components/loading/loading.component';
import { PageContainer } from '../../../components/common-components/page/page-container/page-container.component';
import { RedirectWithStatus } from '../../../components/redirect-w-status';
import { BookAppointmentSection } from '../../../components/showroom-components/book-appointment-section/book-appointment-section.component';
import {
	FergusonHomeLogoWithViewBoxSvg,
	FergusonStudioLogoWithViewBoxSvg
} from '../../../components/showroom-components/ferguson-bkl-logo-with-viewbox/ferguson-bkl-logo-with-viewbox.component';
import { LocationInformationSection } from '../../../components/showroom-components/location-information-section/location-information-section.component';
import { PrepareTodayWithProjects } from '../../../components/showroom-components/prepare-today-with-projects/prepare-today-with-projects.component';
import { ProAssociationBadge } from '../../../components/showroom-components/pro-association-badge/pro-association-badge.component';
import { ShowroomAppointmentBookingModal } from '../../../components/showroom-components/showroom-appointment-booking-modal/showroom-appointment-booking-modal.component';
import { ShowroomHeroImages } from '../../../components/showroom-components/showroom-hero-images/showroom-hero-images.component';
import { ShowroomMeetExpert } from '../../../components/showroom-components/showroom-meet-expert/showroom-meet-expert.component';
import { ShowroomVideo } from '../../../components/showroom-components/showroom-video/showroom-video.component';
import { SHOWROOM_LINK, SHOWROOM_LOCATION_LINK } from '../../../constants/links';
import { FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL } from '../../../constants/showroom';
import { isChromatic } from '../../../helpers/general-helper/general-helper';
import { displayAddress, getHumanReadableLocationType, isSupportedLocationType } from '../../../helpers/showroom/showroom.helper';
import { useTrackPageView } from '../../../hooks/analytics/analytics.hooks';
import { useEmployee } from '../../../hooks/apollo/employee/employee.hooks';
import { SHOWROOM_DETAILS } from '../../../queries/showroom/showroom.queries';

const LoadableUpdateLocationData = loadable(
	() =>
		import(
			/* webpackChunkName: "updatelocationdata" */ '../../../components/internal-components/update-location-data/update-location-data.component'
		),
	{
		resolveComponent: ({ UpdateLocationData }) => UpdateLocationData
	}
);

export const ShowroomLocationDetail: FunctionComponent = () => {
	const pageName = 'landing_pages:showroom_detail_landing';
	const showroomDataLayer = {
		page: pageName,
		isShowroom: true
	};

	const { pathname } = useLocation();
	const { branchPageUrl } = useParams<{ branchPageUrl: string }>();
	const { employee } = useEmployee();
	const isInternal = employee.isAuthenticated;

	useTrackPageView({ pageName }, showroomDataLayer);

	const { data, loading } = useQuery<ShowroomDetailsQuery, ShowroomDetailsQueryVariables>(SHOWROOM_DETAILS, {
		variables: { id: branchPageUrl }
	});

	const [selectedBranchPageUrl, setSelectedBranchPageUrl] = useState<string | undefined>(undefined);
	const onBookAppointment = (): void => {
		setSelectedBranchPageUrl(branchPageUrl);
	};
	const onCloseBookAppointment = (): void => {
		setSelectedBranchPageUrl(undefined);
	};

	if (loading) {
		return <PageLoading />;
	}

	// Redirect to main showroom page if the given showroom wasn't found or it could not load.
	if (!data || !data.showroomDetails) {
		return <RedirectWithStatus path={pathname} to={SHOWROOM_LINK} />;
	}

	const { address, branchMessage, headline, hours, aboutUs, locationImages, nearbyBusinesses, proAssociations, storeInfo, locationType } =
		data.showroomDetails;
	// Redirect to main showroom page if the given showroom is not a supported business type.
	// TODO Redirect to the corresponding warehouse detail page on the Ferguson website if the location is not supported.
	if (!isSupportedLocationType(locationType)) {
		return <RedirectWithStatus path={pathname} to={SHOWROOM_LINK} />;
	}

	const { city, state, zipCode } = address || {};
	const { phoneNumber, servingText, appointmentRequired, appointmentUrl } = storeInfo || {};
	const allowBookAppointment = Boolean(appointmentUrl);

	const breadcrumbs = [
		{
			name: 'Showrooms',
			url: '/showroom'
		},
		{
			name: `${city}, ${state} ${getHumanReadableLocationType(locationType)}`
		}
	];

	const shouldShowBookingModal = selectedBranchPageUrl && locationType && city && state && zipCode && !isChromatic();

	return (
		<PageContainer
			pageTitle={`Ferguson Kitchen & Bath Showroom in ${city}, ${state} ${zipCode}`}
			canonicalURL={pathname}
			metaDescription={`Visit your local Ferguson Bath, Kitchen & Lighting Gallery in ${city}, ${state}. Our showroom offers premium products for your next renovation or build.`}
			breadcrumbs={breadcrumbs}
			borderStyle="b--theme-white">
			<>
				{shouldShowBookingModal ? (
					<div>
						<ShowroomAppointmentBookingModal
							branchPageUrl={selectedBranchPageUrl}
							locationTitle={getHumanReadableLocationType(locationType)}
							locationCity={city}
							locationState={state}
							locationZip={zipCode}
							onCancelFlow={onCloseBookAppointment}
							onFinishFlow={onCloseBookAppointment}
						/>
					</div>
				) : null}
				{/* LEAP data input */}
				{isInternal && (
					<div className="mb3">
						<LoadableUpdateLocationData />
					</div>
				)}
				{/* Branch Message */}
				{branchMessage && (
					<div className="mb3">
						<AlertNotification size="LARGE" alertType="warning">
							{branchMessage}
						</AlertNotification>
					</div>
				)}

				{/* Header Section */}
				<section className="flex flex-column-reverse flex-row-ns items-center w-100 mv5">
					<div className="w-100 mt7-ns mt4">
						<div className="flex">
							<div className="w-80-l w-90-m w-100" data-testid="ferguson-studio-logo">
								{branchPageUrl === FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL ? (
									<FergusonStudioLogoWithViewBoxSvg />
								) : (
									<FergusonHomeLogoWithViewBoxSvg />
								)}
							</div>
						</div>
						<div>
							<p className="f5 fw4 b lh-copy mb5 mt3">{getHumanReadableLocationType(locationType)}</p>
							<p className={`f5 fw7 mb1 mt0 theme-grey-dark ${!servingText ? 'mb5' : ''}`}>
								{displayAddress({ city, state, zipCode })}
							</p>

							{servingText && (
								<p data-testid="serving-text" className="theme-grey lh-copy mt1 mb5">
									{servingText}
								</p>
							)}

							<div className="w-60-ns">
								<div className="tc tl-ns nowrap">
									<BookAppointmentSection
										allowBookAppointment={allowBookAppointment}
										onBookAppointment={onBookAppointment}
										appointmentRequired={appointmentRequired}
										phoneNumber={phoneNumber}
										showSecondaryCta={true}
									/>
								</div>
							</div>
						</div>
						<div className="dn db-ns pt5 pb3"></div>
					</div>
					<div className="w-100 mb6 ml2 ml0-ns">
						<ShowroomHeroImages images={locationImages} />
					</div>
				</section>
				<LocationInformationSection hours={hours} storeInfo={storeInfo} address={address} nearbyBusinesses={nearbyBusinesses} />
				<div className="mv5 b--theme-grey-light bt db-ns mt4" />
				<div className="flex flex-column items-center pa0 mt4 mt0-ns">
					<div className="tc-ns f6 f4-l lh-copy">
						<div className="mb4 tl f6 f4-l w-100-ns lh-copy">
							{/*  DYNAMIC CONTENT BASED ON LOCATION TYPE	*/}
							<h2 className="f3 f2-ns fw6 lh-solid normal mt0-ns ttc">{headline || 'Bring Your Vision to Us'}</h2>
							<p className="ma0 w-80-l w-90-m  f5">{aboutUs}</p>
						</div>
						<ShowroomVideo />
					</div>

					<div className="flex flex-wrap w-100 f5 lh-copy">
						<section className="w-100-ns pb5-ns pa0-ns mh0-ns">
							<div className="mt6">
								<ShowroomMeetExpert backgroundColor="primary-lighter" action="button" buttonOnClick={onBookAppointment} />
							</div>
							{/* PROFESSIONAL ASSOCIATIONS */}
							{proAssociations?.length ? (
								<div className="flex flex-column items-center pb4">
									<h2 className="f3 f2-ns fw6 mt0 mb3 pt5" data-testid="pro-associations">
										Professional Associations & Memberships
									</h2>
									<div className="flex">
										{proAssociations.map((association, index) => (
											<div className="ma3" key={association.url}>
												<ProAssociationBadge key={`pro-association-${index}`} {...association} />
											</div>
										))}
									</div>
								</div>
							) : (
								<div className="mt5" />
							)}
							<div className="pt5-ns pt6">
								<PrepareTodayWithProjects />
							</div>
						</section>
					</div>
				</div>
			</>
		</PageContainer>
	);
};

export const RoutedShowroomLocation = configureRoute(
	{ path: SHOWROOM_LOCATION_LINK, exact: false, DANGEROUSLY_IS_LIVE: true },
	ShowroomLocationDetail
);
