import { type FunctionComponent } from 'react';
import { PLUMBING_LOCATION_PARAGRAPH } from '../../../constants/showroom';
import {
	displayAddress,
	displayHours,
	getFullDayName,
	makePlumbingLocationUrl,
	makeShowroomLocationLink
} from '../../../helpers/showroom/showroom.helper';
import type { PartialShowroomAddress, ShowroomDayHours, ShowroomNearbyBusinesses, ShowroomStoreInfo } from '../../../types/showroom.types';
import { formatPhoneNumber } from '../../../utils/string/phone-number.utils';
import { Link } from '../../common-components/link/link.component';
import { StyledLink } from '../../common-components/link/styled-link.component';
import { Table, TableBody, TableCell, TableRow } from '../../common-components/table/table.component';
import { locationInfoSection } from './location-information-section.css';

type LocationInformationSectionProps = {
	hours: ShowroomDayHours[];
	address: PartialShowroomAddress;
	storeInfo: ShowroomStoreInfo;
	nearbyBusinesses: ShowroomNearbyBusinesses[];
};

export const LocationInformationSection: FunctionComponent<LocationInformationSectionProps> = ({
	hours,
	address,
	storeInfo,
	nearbyBusinesses
}) => {
	const { phoneNumber, faxNumber, plumbingLocationUrl } = storeInfo;
	const { addressLine1, addressLine2, city, state, zipCode } = address;

	const address2String = addressLine2 ? `${addressLine2}, ` : '';
	const addressSearchString = `${addressLine1}, ${address2String}${city}, ${state} ${zipCode}`;
	const truncatedNearbyBusinesses = plumbingLocationUrl ? nearbyBusinesses.slice(0, 2) : nearbyBusinesses.slice(0, 7);

	return (
		<section className={`${locationInfoSection} pt5`} data-testid="location-info-section">
			<div>
				<p className="mb3 mt1 f4 b">Address</p>
				<div className="mb2 f5 nested-copy-line-height">
					{addressLine1 && (
						<p className="ma0" data-testid="address-street-1">
							{addressLine1}
						</p>
					)}
					{addressLine2 && (
						<p className="ma0" data-testid="address-street-2">
							{addressLine2}
						</p>
					)}
					<p className="ma0">{displayAddress({ city, state, zipCode })}</p>

					<p className="ma0">
						<StyledLink color="primary" url={`https://www.google.com/maps/place/${encodeURIComponent(addressSearchString)}`}>
							Get Directions
						</StyledLink>
					</p>
					{phoneNumber || faxNumber ? (
						<div className="mt4">
							{phoneNumber && (
								<p className="ma0" data-testid="phone-number">
									Phone:{' '}
									<StyledLink color="primary" url={`tel:${phoneNumber}`}>
										{formatPhoneNumber(phoneNumber)}
									</StyledLink>
								</p>
							)}
							{faxNumber && (
								<p className="ma0" data-testid="fax-number">
									Fax: {formatPhoneNumber(faxNumber)}
								</p>
							)}
						</div>
					) : null}
				</div>
			</div>
			<div className="dn db-ns b--theme-grey-light br" />
			<div className="mv2 db dn-ns bt b--theme-grey-light" />
			<div>
				<p className="mb3 mt1 f4 b">Hours</p>
				<Table className="collapse w-100 f6 bn">
					<TableBody>
						{hours?.map((hour) => (
							<TableRow key={hour.dayName} className="bn">
								<TableCell className="bn b--theme-grey-light bn-ns pv2 ttc fw7">{getFullDayName(hour.dayName)}</TableCell>
								<TableCell className="bn b--theme-grey-light bn-ns pv2">{displayHours(hour.hours)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div className="dn db-ns b--theme-grey-light br" />
			{truncatedNearbyBusinesses?.length && plumbingLocationUrl ? <div className="mv2 db dn-ns bt b--theme-grey-light" /> : null}
			<div>
				{truncatedNearbyBusinesses?.length ? (
					<div className="flex flex-column" data-testid="nearby-locations">
						<p className="mb1 mt1 f4 b">Nearby Locations</p>
						{truncatedNearbyBusinesses.map((location) => (
							<StyledLink color="primary" className="pv2" key={location.url} url={makeShowroomLocationLink(location.url)}>
								{location.name}
							</StyledLink>
						))}
					</div>
				) : null}

				{plumbingLocationUrl && (
					<div data-testid="plumbing-counter-info" className="pt3">
						<p className="mb1 f5 b">Looking for a Ferguson plumbing counter?</p>
						<p className="f6">{PLUMBING_LOCATION_PARAGRAPH}</p>
						<Link className="f6 f5-ns" url={makePlumbingLocationUrl(plumbingLocationUrl)}>
							View Ferguson Plumbing Location Details
						</Link>
					</div>
				)}
			</div>
		</section>
	);
};
