import { type FunctionComponent } from 'react';
import { FEATURE_FLAGS } from '../../../constants/general';
import { useFeature } from '../../../hooks/features/features.hooks';
import { formatPhoneNumber } from '../../../utils/string/phone-number.utils';
import { StyledButton } from '../../buttons';
import { LinkButton } from '../../buttons/link-button/link-button.component';

type BookAppointmentSectionProps = {
	allowBookAppointment: boolean;
	onBookAppointment: () => void;
	appointmentRequired: boolean | null;
	phoneNumber: string | undefined;
	showSecondaryCta: boolean;
};

export const BookAppointmentSection: FunctionComponent<BookAppointmentSectionProps> = ({
	allowBookAppointment,
	onBookAppointment,
	appointmentRequired,
	phoneNumber,
	showSecondaryCta
}) => {
	const telURL = `tel:${phoneNumber}`;
	const useOnlineAppointmentBooking = useFeature(FEATURE_FLAGS.SHOWROOM_ENABLE_ONLINE_APPOINTMENT_BOOKING);

	if (useOnlineAppointmentBooking && allowBookAppointment) {
		return (
			<>
				<div className="dn dib-ns">
					<StyledButton onClick={onBookAppointment}>Book Your Appointment</StyledButton>
				</div>
				<div className="w-100 dn-ns">
					<StyledButton onClick={onBookAppointment} size={'LARGE'}>
						Book Your Appointment
					</StyledButton>
				</div>

				<p className="f7 i mt2 mb0">Appointments {appointmentRequired ? 'required' : 'recommended'}.</p>

				{showSecondaryCta && phoneNumber && (
					<div className="w-100 mt4 db dn-ns">
						<LinkButton url={telURL} buttonStyle={'SECONDARY'}>
							Call: {formatPhoneNumber(phoneNumber)}
						</LinkButton>
					</div>
				)}
			</>
		);
	}

	if (phoneNumber) {
		return (
			<div data-testid="phone-button-only">
				<div className="dn dib-ns">
					<LinkButton url={telURL} buttonStyle="PRIMARY">
						Call: {formatPhoneNumber(phoneNumber)}
					</LinkButton>
				</div>
				<div className="w-100 dn-ns">
					<LinkButton url={telURL} buttonStyle="PRIMARY" size="LARGE">
						Call: {formatPhoneNumber(phoneNumber)}
					</LinkButton>
				</div>
				<p className="f7 i mb0 mt2 b">Please call to book an appointment.</p>
				<p className="f7 i mb0 mt1">Appointments {appointmentRequired ? 'required' : 'recommended'}.</p>
			</div>
		);
	}

	return null;
};
